<template>
  <div class="flex w-full">
    <img :src="imagePath" alt="banner">
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useScreen } from 'vue-screen';

  export default defineComponent({
    name: 'Banner',

    setup() {
      const screen = useScreen();
      const imagePath = computed(() => {
        if (screen.width >= 768) {
          return require('@/assets/images/Banner-desktop.png');
        } else if (screen.width < 768 && screen.width > 375 ) {
          return require('@/assets/images/Banner-tablet.png');
        } else {
          return require('@/assets/images/Banner-mobile.png');
        }
      });

      return {
        imagePath
      };
    }
  });
</script>
