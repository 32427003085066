<template>
  <div>
    <Banner class="mb-25" />

    <div class="bg-white p-15 md:p-28 rounded-5 mb-20">
      <p class="text-dark-cl-20 text-md md:text-1xl font-medium mb-20">Make an Appointment for</p>
      <!-- A P P O I N T M E N T S -->
      <ul>
        <li
          v-for="(item, i) of appintmentsList"
          :key="i"
          class="text-primary flex items-center mb-10 xs:mb-12"
        >
          <AppIcon
            name="point"
            size="7"
            class="mr-10"
          />
          <a
            :href="item.url"
            target="_blank"
            class="text-sm xs:text-base"
          >
            <span v-if="item.text">{{ item.text }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="bg-white p-15 md:p-28 rounded-5">
      <p class="text-dark-cl-20 text-md md:text-1xl font-medium mb-20">Learn About Available Services & Programs</p>
      <AppCollapse v-model="openedItems">
        <AppCollapseItem
          v-for="(item, index) of myEssentialsList"
          :key="index"
          :name="item.title"
          toggleOnHeader
          class="mb-20 px-28 py-20 border border-grey-fp-30 rounded"
        >
          <!-- H E A D E R -->
          <template #header="{ collapsed, toggleCollapse }">
            <div
              :class="collapsed"
              class="flex items-center cursor-pointer"
              @click="toggleCollapse"
            >
              <div
                v-if="item.logoUrl"
                class="mr-12"
              >
                <img
                  v-if="collapsed"
                  :src="item.logoUrl"
                  alt=""
                  class="h-40 min-w-40"
                >
                <img
                  v-else
                  :src="item.logoUrlActive"
                  alt=""
                  class="h-40 min-w-40"
                >
              </div>

              <div v-else class="min-w-40 min-h-40 flex items-center justify-center mr-12">
                <AppIcon
                  :name="item.iconName"
                  size="40"
                  :class="collapsed ? 'text-grey-fp-50' : 'text-primary'"
                />
              </div>

              <p
                class="text-md pr-30 cursor-pointer"
                :class="collapsed ? 'text-grey-fp-80' : 'text-primary'"
              >
                {{ item.title }}
              </p>
            </div>
          </template>

          <!-- T R I G G E R  I C O N -->
          <template #triggerIcon="{ collapsed }">
            <div
              class="toggle-icon rounded-5 flex items-center justify-center transform"
              :class="collapsed ? 'rotate-0 bg-placebo-blue' : 'rotate-180 bg-primary'"
            >
              <AppIcon
                name="arrow-close"
                size="26"
                :class="collapsed ? 'text-primary' : 'text-white'"
              />
            </div>
          </template>

          <!-- C O N T E N T -->
          <template #content>
            <div class="md:pl-50 pt-10">
              <!-- Q U I C K   L I N K S -->
              <ul
                class="mb-15 border-grey-fp-20"
                :class="{'border-b' : item.socialLinks.length}"
              >
                <li
                  v-for="(link, i) of item.quickLinks"
                  :key="i"
                  class="text-primary flex items-center mb-10 xs:mb-12"
                >
                  <AppIcon
                    name="point"
                    size="7"
                    class="mr-10"
                  />
                  <a
                    :href="link.url"
                    target="_blank"
                    class="text-sm xs:text-base"
                  >
                    <span v-if="link.text">{{ link.text }}</span>
                  </a>
                </li>
              </ul>

              <!-- S O C I A L  L I N K S -->
              <div class="flex items-center">
                <div
                  v-for="(link, ii) of item.socialLinks"
                  :key="ii"
                  class="mr-12 last:mr-0"
                >
                  <a
                    :href="link.url"
                    target="_blank"
                  >
                    <AppIcon
                      :name="link.text.toLowerCase()"
                      size="24"
                      alt="Social"
                      class="text-primary"
                    />
                  </a>
                </div>
              </div>
            </div>
          </template>
        </AppCollapseItem>
      </AppCollapse>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';

  import AppCollapseItem from '@/components/stateless/AppCollapseItem.vue';
  import AppCollapse from '@/components/stateless/AppCollapse.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import Banner from '@/components/Banner.vue';

  export default defineComponent({
    name: 'MyEssentials',

    components: { AppCollapseItem, AppCollapse, AppIcon, Banner },

    setup() {
      const myEssentialsList = ref([
        {
          title: 'Great Oaks Charter School Wilmington',
          logoUrl: require('@/assets/images/img-oaks-grey.png'),
          logoUrlActive: require('@/assets/images/img-oaks-blue.png'),
          iconName: '',
          quickLinks: [
            {
              text: 'Website',
              url: 'https://greatoakswilm.org/parents/'
            },
            {
              text: 'Schoology',
              url: 'http://greatoakswilm.schoology.com'
            },
            {
              text: 'Home Access',
              url: 'http://hacdoe.doe.k12.de.us'
            }
          ],
          socialLinks: [
            {
              text: 'facebook',
              url: 'https://www.facebook.com/gowilmington'
            },
            {
              text: 'instagram',
              url: 'https://www.instagram.com/greatoakswilmington/'
            },
            {
              text: 'twitter',
              url: 'https://twitter.com/go_wilmington'
            },
          ]
        },
        {
          title: 'Kuumba Academy Charter School',
          logoUrl: require('@/assets/images/img-kuumba-grey.png'),
          logoUrlActive: require('@/assets/images/img-kuumba-blue.png'),
          iconName: '',
          quickLinks: [
            {
              text: 'Website',
              url: 'https://kuumbaacademy.org/'
            },
            {
              text: 'Schoology',
              url: 'https://www.schoology.com/'
            },
            {
              text: 'Home Access',
              url: 'http://hacdoe.doe.k12.de.us'
            },
            {
              text: 'Classroom Dojo',
              url: 'https://www.classdojo.com/resources/#parents?live=true'
            }
          ],
          socialLinks: [
            {
              text: 'facebook',
              url: 'https://www.facebook.com/kuumbaacademy'
            },
            {
              text: 'instagram',
              url: 'https://www.instagram.com/kuumba_academy_charter_school/'
            },
            {
              text: 'twitter',
              url: 'https://twitter.com/KuumbaAcademyDE'
            },
          ]
        },
        {
          title: 'Parenting Resources',
          logoUrl: '',
          logoUrlActive: '',
          iconName: 'parenting',
          quickLinks: [
            {
              text: 'Parent Information Center of Delaware',
              url: 'https://picofdel.org/events/'
            }
          ],
          socialLinks: []
        },
        {
          title: 'Community Resources',
          logoUrl: '',
          logoUrlActive: '',
          iconName: 'community',
          quickLinks: [
            {
              text: 'Delaware Libraries',
              url: 'https://lib.de.us/'
            },
            {
              text: 'Osher Lifelong Learning Institute',
              url: 'https://www.olli.udel.edu/'
            },
            {
              text: 'Community Legal Aid Society',
              url: 'http://www.declasi.org/'
            }
          ],
          socialLinks: []
        },
        {
          title: 'Health & Wellbeing Resources',
          logoUrl: '',
          logoUrlActive: '',
          iconName: 'heartbeat',
          quickLinks: [
            {
              text: 'Christiana Care- Patient Portal',
              url: 'https://cernerhealth.com/oauth/authenticate?redirect_uri=https%3A%2F%2Fcernerhealth.com%2Fsaml%2Fsso%2Fresponse%3Fmessage_id%3D_f6f07ba8-c62f-46a3-a696-b9c67e5f9ff5%26issuer%3Dhttps%253A%252F%252Fchristianacare.consumerportal.healtheintent.com%252Fsession-api%252Fprotocol%252Fsaml2%252Fmetadata&sign_in_only=on&client_id=22af60b9d165480292a6472a70ab5e12'
            },
            {
              text: 'DHSS',
              url: 'https://www.dhss.delaware.gov/dhss/'
            },
            {
              text: 'Mobile Health Unit',
              url: 'https://sites.udel.edu/healthycommunities/2020/04/20/community-mobile-healthcare-wellness-program/'
            },
            {
              text: 'Family Counseling Center of St. Pauls',
              url: 'https://stpaulscounseling.org/'
            }
          ],
          socialLinks: []
        },
        {
          title: 'Household Resources',
          logoUrl: '',
          logoUrlActive: '',
          iconName: 'household',
          quickLinks: [
            {
              text: 'CEB Family Resource Center',
              url: 'https://forms.gle/uwB8u76rdY5waR7w6'
            },
            {
              text: 'CEB Food Pantry',
              url: 'https://forms.gle/uwB8u76rdY5waR7w6'
            },
            {
              text: 'Food Bank of DE',
              url: 'https://www.fbd.org/program/'
            },
          ],
          socialLinks: []
        },
        {
          title: 'Financial Resources',
          logoUrl: '',
          logoUrlActive: '',
          iconName: 'finance',
          quickLinks: [
            {
              text: 'Stepping Stones/Delaware Community Reinvestment Action Council',
              url: 'https://www.sscreditunion.org/membership.html'
            }
          ],
          socialLinks: []
        },
        {
          title: 'Career/Professional Resources',
          logoUrl: '',
          logoUrlActive: '',
          iconName: 'career-professional',
          quickLinks: [
            {
              text: 'Indeed',
              url: 'http://indeed.com/'
            },
            {
              text: 'LinkedIn',
              url: 'http://linkedin.com/'
            },
            {
              text: 'Career Cruising',
              url: 'https://public.careercruising.com/en/'
            },
            {
              text: 'Wilmington Alliance',
              url: 'https://www.wilmingtonalliance.org/yes-wilmington-virtual-event'
            },
            {
              text: 'Zipcode Wilmington',
              url: 'https://www.zipcodewilmington.com/'
            },
            {
              text: 'Building Skills 4 Work',
              url: 'https://www.buildingskills4work.org/'
            },
            {
              text: 'My Next Move',
              url: 'https://www.mynextmove.org/'
            },
            {
              text: 'GCF Global',
              url: 'https://edu.gcfglobal.org/en/'
            }
          ],
          socialLinks: []
        },
        {
          title: 'Contact Us',
          logoUrl: '',
          logoUrlActive: '',
          iconName: 'contact-us',
          quickLinks: [
            {
              text: 'Connect with a Coach',
              url: 'https://forms.gle/tKYZ7v12wwXhHkX68'
            }
          ],
          socialLinks: [
            {
              text: 'website',
              url: 'https://www.cebde.org'
            },
            {
              text: 'facebook',
              url: 'https://www.facebook.com/CommunityEducationBuilding'
            },
            {
              text: 'instagram',
              url: 'https://www.instagram.com/CEBofDE'
            },
            {
              text: 'twitter',
              url: 'https://twitter.com/CEBofDE'
            },
          ]
        }
      ]);
      const appintmentsList = ref([
        {
          text: 'Food Pantry',
          url: 'https://forms.gle/uwB8u76rdY5waR7w6'
        },
        {
          text: 'Christina Care',
          url: 'https://accounts.mychristianacare.health/saml2/sso/login?authenticationRequestId=ddffe223-e144-4dc9-9a81-dee4a2b5abc0'
        },
      ]);
      const openedItems = ref([]);

      return {
        myEssentialsList,
        appintmentsList,
        openedItems
      };
    }

  });
</script>

<style lang="scss" scoped>
  .toggle-icon {
    @apply transition duration-300;
  }
</style>